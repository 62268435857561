<template>
<div>
  <template v-for="op in sortedList">
    <Operator :key=op.exten :worker=op.worker :exten=op.exten :status=op.status :calls_taken=op.calls_taken :caller=op.caller 
              :number=op.number :last_call=op.last_call :channel=op.channel :outgoing=op.outgoing
              :duration=op.duration :durationTrigger=op.durationTrigger
    />
  </template>
</div>
</template>

<script>

import Operator from "phone/page/Operator.vue"
import _ from "underscore"

export default {
  name: "OperatorList",
  
  components: {Operator},
  
  props: {
    list: Array,
  },
  
  data()
  {
    return {
    }
  },
  
  methods: {
  },
  
  computed: {
    sortedList()
    {
      return _.sortBy(this.list, "worker")
    },

  },
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-md-12" },
    _vm._l(_vm.queues, function(q) {
      return _c("div", { key: q.queue, staticClass: "panel panel-default" }, [
        _c("div", { staticClass: "panel-heading" }, [_vm._v(_vm._s(q.title))]),
        _vm._v(" "),
        _c("div", { staticClass: "panel-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-9" },
              [_c("OperatorList", { attrs: { list: q.members } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticStyle: { padding: "0 20px" } },
                [_c("AbonQueue", { attrs: { list: q.abonents } })],
                1
              )
            ])
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box", class: _vm.opClass, attrs: { title: _vm.opTitle } },
    [
      _c("img", {
        staticClass: "img-circle",
        staticStyle: { height: "45px", width: "45px", "margin-right": "10px" },
        attrs: { src: _vm.opAvatar }
      }),
      _vm._v(" "),
      _vm.needsDescr
        ? [
            _vm.outgoing ? [_vm._v("➜")] : _vm._e(),
            _vm._v(" "),
            !_vm.caller
              ? [_vm._v("Внутренний звонок")]
              : _vm.caller.match(/^\+?\d+$/)
              ? [
                  _c("a", { attrs: { href: `tel:${_vm.number}` } }, [
                    _vm._v(_vm._s(_vm.niceNumber))
                  ])
                ]
              : [
                  _c("a", { attrs: { href: _vm.uid, title: _vm.niceNumber } }, [
                    _vm._v(_vm._s(_vm.caller))
                  ])
                ],
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-left": "1em", "margin-right": "1em" } },
              [_vm._v(_vm._s(_vm.showSeconds()))]
            )
          ]
        : [
            _c("div", { staticClass: "calls" }, [
              _vm._v(_vm._s(_vm.opCallsTaken))
            ])
          ],
      _vm._v(" "),
      _vm.needsMenu
        ? _c("div", { staticClass: "dropdown" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default dropdown-toggle modest",
                attrs: {
                  type: "button",
                  id: `dropdownMenu-${_vm.exten}`,
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "true"
                }
              },
              [_c("span", { staticClass: "caret" })]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass: "dropdown-menu",
                attrs: { "aria-labelledby": "`dropdownMenu-${exten}`" }
              },
              [
                _c("li", [
                  _c(
                    "a",
                    { on: { click: _vm.spy } },
                    [
                      _c("Icon", { attrs: { name: "fa-user-secret" } }),
                      _vm._v(" Прослушивать")
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    { on: { click: _vm.whisper } },
                    [
                      _c("Icon", { attrs: { name: "fa-microphone" } }),
                      _vm._v(" Подсказывать")
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.channel
                  ? [
                      _c("li", {
                        staticClass: "divider",
                        attrs: { role: "separator" }
                      }),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          { on: { click: _vm.hangup } },
                          [
                            _c("Icon", { attrs: { name: "fa-close" } }),
                            _vm._v(" Прервать")
                          ],
                          1
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
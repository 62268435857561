var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.sortedList, function(op) {
        return [
          _c("Operator", {
            key: op.exten,
            attrs: {
              worker: op.worker,
              exten: op.exten,
              status: op.status,
              calls_taken: op.calls_taken,
              caller: op.caller,
              number: op.number,
              last_call: op.last_call,
              channel: op.channel,
              outgoing: op.outgoing,
              duration: op.duration,
              durationTrigger: op.durationTrigger
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="box" :title=opTitle v-bind:class=opClass >
  
  <img :src=opAvatar class="img-circle" style="height: 45px; width: 45px; margin-right: 10px" />
  <template v-if="needsDescr">
    <template v-if="outgoing">➜</template>
    <template v-if="!caller">Внутренний звонок</template>
    <template v-else-if="caller.match(/^\+?\d+$/)"><a :href="`tel:${number}`" >{{niceNumber}}</a></template>
    <template v-else><a :href=uid :title=niceNumber>{{caller}}</a></template>
    <div style="margin-left: 1em; margin-right: 1em">{{showSeconds()}}</div>
  </template>
  <template v-else><div class="calls">{{opCallsTaken}}</div></template>
  
  <div class="dropdown" v-if="needsMenu">
    <button class="btn btn-default dropdown-toggle modest" type="button" :id="`dropdownMenu-${exten}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="`dropdownMenu-${exten}`">
        <li><a @click=spy><Icon name="fa-user-secret" />&nbsp;Прослушивать</a></li>
        <li><a @click=whisper><Icon name="fa-microphone" />&nbsp;Подсказывать</a></li>
        <template v-if="channel">
          <li role="separator" class="divider"></li>
          <li><a  @click=hangup><Icon name="fa-close" />&nbsp;Прервать</a></li>
        </template>
    </ul>
  </div>
</div>

</template>

<script>

import {formatUptime, notifyHttpError} from "common.js"
import moment from "moment"
import darsan from "darsan"

export default {
  name: "Operator",
  
  props:
  {
    worker: {
      type: String,
      required: true,
    },
    exten: {
      type: [Number,String],
      required: true,
    },
    status: {
      type: String,
    },
    calls_taken: {
      type: [Number,String],
      default: 0,
    },
    number: {
      type: String,
      default: null,
    },
    caller: {
      type: String,
      default: null,
    },
    last_call: {
      type: [Number,String],
      default: null,
    },
    channel: {
      type: String,
    },
    duration: {
      type: [Number,String],
    },
    durationTrigger: {
      type: Number,
    },
    outgoing: {
      type: Boolean,
    },
  },
  
  data()
  {
    return {
      seconds: 0,
      timer: null,
    }
  },

  mounted()
  {
    this.timer = setInterval(() => this.seconds++, 1000)
  },
              
  unmounted()
  {
    clearInterval(this.timer)
  },  
  
  methods: {
  
    showSeconds(sec)
    {
      return formatUptime(this.seconds)
    },
    
    hangup()
    {
      darsan.delete("", "phone", `/channel/${this.channel}`)
        .catch(xhr => notifyHttpError(xhr))
    },
    
    spy()
    {
      darsan.post("", "phone", `/exten/${user.sip}/spy/${this.exten}`)
        .catch(xhr => notifyHttpError(xhr))
    },
    
    whisper()
    {
      darsan.post("", "phone", `/exten/${user.sip}/spy/${this.exten}`, {whisper: 1})
        .catch(xhr => notifyHttpError(xhr))
    },
    
  },
  
  computed: {
    
    opTitle()
    {
      let title = [`${this.worker} #${this.exten}`]
      
      if (this.last_call && this.last_call<moment().unix()) 
      {
        title.push(`Последний звонок: ${moment(this.last_call*1000).locale("ru").fromNow()}`);
      }
      
      return title.join("\n")
    },
    
    opClass()
    {
      const cl = {
        [this.status]: true,
      }
      if (!this.caller) cl["with-calls"] = true
      return cl
    },
    
    uid()
    {
      const res = this.caller.match(/^(\w+)/)
      return res ? "/client/" + res[1] : this.caller
    },
    
    niceNumber()
    {
      const n = this.number.replace(/^\+7/, "")
      const res = n.match(/(\d\d\d)(\d\d)(\d\d)$/)

      if (res)
      {
        return "(" + n.substr(0, res.index) + ") " + res[1] + "-" + res[2] + "-" + res[3]
      }
      else
      {
        return n
      }
    },
    
    opAvatar()
    {
      return `${API}/avatar/${this.worker}`
    },
    
    opCallsTaken()
    {
      return this.calls_taken ? this.calls_taken : ""
    },
    
    needsMenu()
    {
      return (this.status=="in-use" || this.status=="ringing") && this.caller
    },
    
    needsDescr()
    {
      return this.status=="in-use" || this.status=="ringing"
    },
    
  },
  
  watch: {
    durationTrigger: {
      immediate: true,
      handler(val) 
      {
        this.seconds = this.duration
      },
    },
  },
}
</script>

<style scoped>
.box {
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: 5px 5px 10px 1px rgb(200,200,200);
  padding: 3px 3px 3px 8px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 26em;
}

.box.with-calls {
 justify-content: left !important;
}

.calls {
  font-size: x-large;
  margin-left: 0.5em;
}
.in-use {
  border: 1px solid blue;
  background: #d6f5f5;
}
.ringing {
  border: 1px solid orange;
  background: #fff5e6;
}
.not-in-use {
  border: 1px solid green;
  background: #eeffcc;
}

.modest {
  background: none;
  border: none;
}
</style>
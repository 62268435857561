var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.sortedQueue, function(a, i) {
      return _c("Abon", {
        key: a.number,
        attrs: {
          position: i + 1,
          seconds: a.seconds,
          caller: a.caller,
          number: a.number
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
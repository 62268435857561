var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("strong", [_vm._v(_vm._s(_vm.position))]),
      _vm._v(" "),
      _vm.caller.match(/^\+?\d+$/)
        ? [
            _c("a", { attrs: { href: `tel:${_vm.number}` } }, [
              _vm._v(_vm._s(_vm.niceNumber))
            ])
          ]
        : [
            _c("a", { attrs: { href: _vm.uid, title: _vm.niceNumber } }, [
              _vm._v(_vm._s(_vm.caller))
            ])
          ],
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.showSeconds()))])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }